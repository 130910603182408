<template>
  <div>
    <div v-show="exactRoute">
      <v-card>
        <v-card-title>
          Zákazníci
          <v-spacer></v-spacer>
          <v-form v-model="searchValid" class="d-flex align-center">
            <v-text-field
              ref="searchInput"
              v-model.trim="search"
              append-icon="mdi-magnify"
              :rules="[validation.required]"
              label="Vyhledat"
              single-line
              hide-details
              outlined
              dense
              class="rounded-0"
            ></v-text-field>
            <v-select
              outlined
              class="rounded-0"
              dense
              single-line
              hide-details
              ref="searchTypeInput"
              label="Vyhledat podle"
              :rules="[validation.required]"
              v-model="searchType"
              :items="searchTypes"
            >
            </v-select>
            <v-btn
              color="primary"
              :disabled="!searchValid || runningRequest"
              @click="filterBySearch"
              class="rounded-0"
            >Vyhledat</v-btn>
            <v-btn
              color="error"
              @click="resetSearch()"
              class="rounded-0"
              :disabled="!activeSearch || runningRequest"
            >
              Zrušit filtr
            </v-btn>
          </v-form>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="users"
          class="elevation-1"
          :loading="runningRequest"
          :server-items-length="totalUsers"
          :options.sync="options"
          :footer-props="{'items-per-page-options': [10, 20, 50] }"
        >
          <template v-slot:item.street="{ item }">
            {{item.street}} {{item.streetNumber}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="deleteUser(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="redirectToDetail(item)"
            >
              mdi-information-variant
            </v-icon>
            <v-icon
              v-if="!item.emailVerifiedAt"
              @click="showVerifyDialog(item)"
            >
              mdi-account-check
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog
        width="350"
        v-model="deleteDialog">
        <DeleteResource
          :resource="userToDelete"
          resource-type="admin/users"
          @delete-success="deleteSuccess()"
          @delete-failure="showDeleteFailure"
        />
      </v-dialog>
      <v-dialog
        v-model="verifyDialog"
        :dismissable="verifyRequest"
        width="350">
        <v-card>
          <v-card-actions>
            <v-btn
              :loading="verifyRequest"
              color="success"
              @click="verify"
            >
              Potvrdit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <router-view ref="childView"></router-view>
  </div>
</template>

<script>
import archiveHelpers from '@/mixins/local/archiveHelpers'
import DeleteResource from '@/components/DeleteResource'
import { mapActions } from 'vuex'

export default {
  name: 'Users',
  components: { DeleteResource },
  mixins: [archiveHelpers],
  data () {
    return {
      search: '',
      searchType: null,
      activeSearch: false,
      searchValid: true,
      deleteDialog: false,
      verifyDialog: false,
      headers: [
        {
          text: 'Jméno',
          align: 'start',
          value: 'firstName'
        },
        {
          text: 'Příjmení',
          value: 'lastName'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Telefon',
          value: 'phone'
        },
        {
          text: 'Telefon 2',
          value: 'phone2'
        },
        {
          text: 'Ulice a čp.',
          value: 'street'
        },
        {
          text: 'PSČ',
          value: 'zip'
        },
        {
          text: 'Město',
          value: 'city'
        },
        { text: 'Akce', value: 'actions', sortable: false }
      ],
      searchTypes: [],
      totalUsers: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: []
      },
      users: [],
      runningRequest: false,
      exactRoute: true,
      userToDelete: {},
      userToVerify: {},
      verifyRequest: false
    }
  },
  watch: {
    options: {
      deep: true,
      handler () {
        this.loadUsers()
      }
    },
    '$route.params': {
      deep: true,
      handler (newVal, oldVal) {
        // reload pri prechodu z detailu na prehled
        if (oldVal.id && !newVal.id) {
          this.getUsers()
        }
      }
    }
  },
  methods: {
    loadUsers () {},
    getUsers () {
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      this.$http.get('/admin/users', {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          search: this.search,
          // eslint-disable-next-line no-extra-boolean-cast
          searchType: !!this.searchType ? this.searchType : null,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.users = res.data.hosts
          this.totalUsers = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    filterBySearch () {
      this.activeSearch = true
      this.getUsers()
    },
    resetSearch () {
      this.$refs.searchInput.reset()
      this.$refs.searchTypeInput.reset()
      this.activeSearch = false
      this.getUsers()
    },
    redirectToDetail (user) {
      this.$router.push(`/admin/users/${user.id}`)
    },
    deleteUser (user) {
      this.userToDelete = { ...user }
      this.deleteDialog = true
    },
    deleteSuccess () {
      this.showDeleteSuccess()
      this.getUsers()
    },
    showDeleteFailure (payload) {
      this.deleteDialog = false
      if (payload.message.includes('upcoming reservations')) {
        this.setAlerts([{
          message: 'Zákazník má nadcházející rezervace',
          type: 'error'
        }])
      }
    },
    verify () {
      this.verifyRequest = true
      this.$http.post(`/admin/users/${this.userToVerify.id}/verify`)
        .then(res => {
          this.verifyDialog = false
          this.verifyRequest = false
          this.setAlerts([{
            message: 'Uživatel ověřen',
            type: 'success'
          }])
          this.getUsers()
        })
    },
    showVerifyDialog (user) {
      this.verifyDialog = true
      this.userToVerify = user
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  mounted () {
    this.searchTypes = this.headers.slice(0, -2)
    this.getUsers()
    this.loadUsers = this.debounce(this.getUsers, 2000)
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  },
  updated () {
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  }
}
</script>

<style scoped>

</style>
